<template>
  <AppointmentSelector
    :sendInput="onSubmit"
    :state="state"
    :initialValues="initialValues"
    color="grey"
    immediate
  />
</template>

<script>
import { mapState } from 'vuex'
import AppointmentSelector from '@/components/overrideCurrent/apply/appointment-selector'
import '@appsocially/truffle-components/dist/truffle-components.css'
export default {
  components: {
    AppointmentSelector
  },
  props: {
    node: {
      type: Object,
      required: true
    },
    upil: {
      type: Object,
      required: true
    },
    state: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapState('auth', ['uid']),
    initialValues () {
      return this.state[this.inputName] || {}
    },
    inputName () {
      return this.node.input.name
    }
  },
  methods: {
    onSubmit (input) {
      return this.upil.consume(this.node.event, input)
    }
  }
}
</script>

<style>
</style>
